import { useState } from "react";
import { Link } from "react-router-dom";
import useCatalogue from "../../../../hooks/useCatalogue";
import usePromotions from "../../../../hooks/usePromotions";
import { apiRest } from "../../../../hooks/useRest";
import ModalPromotion from "./modals/ModalPromotion";

const Promotions = () => {
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [select, setSelect] = useState(null);
  const [modalCatalogue, setModalCatalogue] = useState(false);
  const [modalPromotions, setModalPromotions] = useState(false);

  const [params_c, setParamsC] = useState({
    url: "/Auth/Catalogo",
  });

  const [params_p, setParamsP] = useState({
    url: "/Auth/Promociones",
  });

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "catalogo":
        {
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          formData.append("id", 1);

          //send api rest
          const response = await apiRest({
            url: "/Auth/Catalogo/Actualizar",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          return setSteps(steps + 1);
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "edit":
        {
          setSelect(value);
          setModalPromotions(true);
        }
        break;
      case "change_status":
        {
          let formData = new FormData();
          formData.append("id", value.data.id);
          formData.append("status", value.status);

          //send api rest
          const response = await apiRest({
            url: "/Auth/Promociones/Actualizar",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          return setSteps(steps + 1);
        }
        break;
    }
  };

  //Hooks
  const { catalogue_data, catalogue_message, catalogue_isLoading } =
    useCatalogue(params_c.url, params_c, steps);

  const { promotions_data, promotions_message, promotions_isLoading } =
    usePromotions(params_p.url, params_p, steps);
  return (
    <div className="container-md">
      <div className="py-5">
        <h1>Catalogos y Promociones</h1>
      </div>

      <div className="row">
        <div className="col-md-2 d-flex">
          {catalogue_data ? 
          catalogue_data.length > 0 ?
          (
            <label className="btn btn-success rounded-pill text-nowrap me-2">
              <a className="text-white" href={catalogue_data[0].url} target="_blank" download>
                <i class="bi bi-download me-1"></i>Descargar
              </a>
            </label>
          ) 
          : ( " " )
          : (
            ""
          )}
          <label className="btn btn-primary rounded-pill text-nowrap">
            <i class="bi bi-plus-lg me-1"></i>Agregar catalogo
            <input
              name="catalogo"
              onChange={(event) => onChange(event)}
              type="file"
              className="d-none"
            />
          </label>
        </div>
        <div className="col-md-12">
          <div className="my-5">
            {catalogue_data ? (
              ""
            ) : (
              <div className="border-0 min-vh-100 d-flex justify-content-center align-items-center ">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-2">
          <label className="btn btn-primary rounded-pill text-nowrap">
            <i class="bi bi-plus-lg me-1"></i>Agregar promocion
            <button
              className="d-none"
              name="modal_art"
              onClick={(event) => setModalPromotions(true)}
            ></button>
          </label>
        </div>
        <div className="col-md-12">
          <div className="my-5">
            {promotions_data ? (
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">IMAGEN</th>
                      <th scope="col">TITULO</th>
                      <th scope="col">ESTADO</th>
                      <th scope="col" colSpan={2}>
                        ACCIONES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {promotions_data.map((promo) => (
                      <tr>
                        <th>{promo.id}</th>
                        <td>
                          <div className="overflow-hidden align-middle">
                            <img
                              src={promo.url}
                              className="img-fluid border"
                              width={"100px"}
                              alt=""
                            />
                          </div>
                        </td>
                        <td>{promo.name}</td>
                        <td>{promo.status_id == 1 ? "Activo" : "Inactivo"}</td>
                        <td>
                          <div class="dropdown">
                            <button
                              class="btn btn-outline-dark rounded-pill dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Cambiar estado...
                            </button>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <label className="btn w-100 d-flex justify-content-start m-0">
                                  <i class="text-success me-1 bi bi-check-circle-fill"></i>
                                  Activar
                                  <button
                                    className="d-none"
                                    name="change_status"
                                    onClick={(event) =>
                                      onClick(event, { data: promo, status: 1 })
                                    }
                                  ></button>
                                </label>
                              </li>
                              <li>
                                <label className="btn w-100 d-flex justify-content-start m-0">
                                  <i class="text-danger me-1 bi bi-x-circle-fill"></i>
                                  Desactivar
                                  <button
                                    className="d-none"
                                    name="change_status"
                                    onClick={(event) =>
                                      onClick(event, { data: promo, status: 2 })
                                    }
                                  ></button>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </td>
                        <td>
                          <label className="btn btn-outline-success">
                            <i class="bi bi-pencil"></i>
                            <button
                              className="d-none"
                              name="edit"
                              onClick={(event) => onClick(event, promo)}
                            ></button>
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="border-0 min-vh-100 d-flex justify-content-center align-items-center ">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {modalPromotions ? (
        <ModalPromotion
          setModal={setModalPromotions}
          select={select}
          setSelect={setSelect}
          setSteps={setSteps}
          steps={steps}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Promotions;
