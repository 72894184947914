import { format } from "date-fns";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import clientAxios from "../../../../../config/ConfigAxios";

const OrderDetails = () => {
  const { id } = useParams();

  const [order, setOrder] = useState(null);

  const Totals = (select) => {
    let cantidad = 0;
    let precio = 0;

    select.map((product) => {
      let p_cantidad = product.quantity >= 1 ? product.quantity : 1;

      //console.log(product);
      if (product.rubros[0].id == 20 || product.rubros[0].id == 13 || product.rubros[0].id == 14 || product.rubros[0].id == 22 || product.rubros[0].id == 23 || product.rubros[0].id == 24 || product.rubros[0].id == 28) {
        precio = p_cantidad * (parseFloat(product.price_unit) * 0.874) + precio;
      }

      if (product.rubros[0].id == 29) {
        precio = p_cantidad * (parseFloat(product.price_unit) * 1) + precio;
      }

      if (product.rubros[0].id == 12 || product.rubros[0].id == 26 || product.rubros[0].id == 27) {
        precio = p_cantidad * (parseFloat(product.price_unit) * 0.8096) + precio;
      }

      cantidad = cantidad + p_cantidad;
    });

    return {
      cantidad: cantidad,
      precio: precio,
    };
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "print":
        {
          window.print();
        }
        break;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let params = {
        id: id,
        pagination: 10,
      };
      const response = await clientAxios.get("/Auth/Pedidos", { params });
      setOrder(response.data.data);
    };
    if (id) {
      fetch();
    }
  }, [id]);

  //console.log(order);
  return (
    <>
      {order ? (
        <div
          className="row border border-dark border-3 m-auto"
          style={{ maxWidth: "845px" }}
        >
          <div className="col-md-12 text-center">
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex justify-content-between">
                <div className="fw-bold">
                  <span className="fw-normal">FECHA DE CREACIÓN </span>
                  {format(
                    new Date(order.created_at),
                    "d/M/yyyy H:ii:ss"
                  ).toString()}
                </div>
                <div>
                  <label className="btn btn-outline-dark border-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    <button
                      className="d-none"
                      name="print"
                      onClick={(event) => onClick(event)}
                    ></button>
                  </label>
                </div>
              </li>

              <li class="list-group-item mb-3 border-0 text-start">
                <div className="fw-bold">
                  <span className="fw-normal">MAIL </span>
                  {order.users.email}
                </div>
                <div className="fw-bold">
                  <span className="fw-normal">NOMBRE </span>
                  {order.users.name + " " + order.users.last_name}
                </div>
              </li>

              <li class="list-group-item mb-3 border-0 d-flex justify-content-between">
                <div className="fw-bold">
                  <span className="fw-normal">CODIGO </span>
                  {order.code}
                </div>
                <div className="fw-bold">
                  <span className="fw-normal">Metodo de envio </span>
                  {order.shipping_methods.description}
                </div>
              </li>

              <li class="list-group-item mb-3 border-0 d-flex justify-content-between">
                <div className="fw-bold">OBSERVACIONES</div>
                <div>{order.observation}</div>
              </li>

              {order.type_payments.map((payment) => (
                <li class="list-group-item border-0">
                  <div className="fw-bold mb-3">
                    <span className="fw-normal">METODO DE PAGO: </span>
                    {payment.payment_method}
                  </div>
                  <div className="fw-bold mb-3 d-none">
                    <span className="fw-normal">RECARGO: </span>%
                    {-payment.coefficient}
                  </div>
                  <div className="fw-bold mb-3">
                    <span className="fw-normal">TOTAL A PAGAR: </span>$
                    {Totals(order.reports).precio.toFixed(2)}
                  </div>
                </li>
              ))}

              <li class="list-group-item mb-3 border-0">
                <div className="fw-bold">PRODUCTOS</div>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-nowrap">Orden Cod.</th>
                        <th className="text-nowrap">Rubro</th>
                        <th className="text-nowrap">Nombre</th>
                        <th className="text-nowrap">Codigo Prd.</th>
                        <th className="text-nowrap">Cantidad</th>
                        <th className="text-nowrap">Precio Unidad</th>
                        <th className="text-nowrap">Precio Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.reports.map((product) => {
                        let precio = 0;
                        let total = 0;
                        let cantidad = product.quantity >= 1 ? product.quantity : 1;

                        console.log(product);

                        if (product.rubros[0].id == 20 || product.rubros[0].id == 13 || product.rubros[0].id == 14 || product.rubros[0].id == 22 || product.rubros[0].id == 23 || product.rubros[0].id == 24 || product.rubros[0].id == 28) {
                          precio = (parseFloat(product.price_unit) * 0.874);
                          total = cantidad * precio;
                        }
                  
                        if (product.rubros[0].id == 29) {
                          precio = (parseFloat(product.price_unit) * 1);
                          total = cantidad * precio;
                        }
                  
                        if (product.rubros[0].id == 12 || product.rubros[0].id == 26 || product.rubros[0].id == 27) {
                          precio = (parseFloat(product.price_unit) * 0.8096);
                          total = cantidad * precio;
                        }

                        
                        return (
                          <tr>
                            <td className="text-start">
                              {product.code_orders}
                            </td>
                            <td className="text-start">{product.rubros[0].name}</td>
                            <td className="text-start">{product.name}</td>
                            <td className="text-start">{product.code}</td>
                            <td className="text-end">{cantidad}</td>
                            <td className="text-nowrap">
                              ${(precio).toFixed(2)}
                            </td>
                            <td className="text-nowrap">
                              ${" "}
                              {(total).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>TOTAL</td>
                        <td colSpan={4} className="text-end">
                          {Totals(order.reports).cantidad}
                        </td>
                        <td className="text-center text-nowrap"></td>
                        <td className="text-center text-nowrap">
                          ${Totals(order.reports).precio.toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default OrderDetails;
