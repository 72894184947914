const ModalUser = ({ form, setForm, data, onClick, alert, actions, loading }) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "id":
      case "name":
      case "address":
      case "rol":
      case "confirmation_password":
      case "password":
      case "phone":
      case "email":
      case "last_name":
      case "name":
      case "username":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  //Concat data user
  const initialState = {
    username: "",
    name: "",
    last_name: "",
    email: "",
    phone: "",
    roles: [],
    status: null,
    address: "",
  }

  const {
    id,
    username,
    name,
    last_name,
    email,
    phone,
    roles,
    status,
    address,
  } = data? data : initialState;

  //Logs
  //console.log(data);
  return (
    <div
      class="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "700px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>{data ? "Actualizar usuario" : "Agregar usuario nuevo"}</h1>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-3">
            <form className="row bg-white p-3" autoComplete="new-off">
              <div class="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  ID
                </label>
                <input
                  type="number"
                  autoComplete="off"
                  class="form-control border-primary rounded-pill"
                  placeholder="Ingresar ID de usuario"
                  name="id"
                  onChange={(event) => onChange(event)}
                  defaultValue={id}
                />
              </div>
              <div class="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Username
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  class="form-control border-primary rounded-pill"
                  placeholder="Ingresar nombre de usuario"
                  name="username"
                  onChange={(event) => onChange(event)}
                  defaultValue={username}
                />
              </div>
              <div class="col-md-6 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  class="form-control border-primary rounded-pill"
                  placeholder="Ingresar nombre"
                  name="name"
                  onChange={(event) => onChange(event)}
                  defaultValue={name}
                  autoComplete="nope"
                />
              </div>
              <div class="col-md-6 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Apellido
                </label>
                <input
                  type="text"
                  class="form-control border-primary rounded-pill"
                  placeholder="Ingresar apellido"
                  name="last_name"
                  onChange={(event) => onChange(event)}
                  defaultValue={last_name}
                  autoComplete="last_name"
                />
              </div>
              <div class="col-md-12 mb-3">
                <label for="exampleFormControlInput1" class="form-label">
                  Email
                </label>
                <input
                  type="email"
                  class="form-control border-primary rounded-pill"
                  placeholder="Ingresar email"
                  name="email"
                  onChange={(event) => onChange(event)}
                  defaultValue={email}
                  autoComplete="nope"
                />
                {alert ? (
                  form.email.trim() == "" ? (
                    <span className="text-danger">
                      Falta completar su email
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div class="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Telefono
                </label>
                <input
                  type="number"
                  class="form-control border-primary rounded-pill"
                  placeholder="Ingresar telefono"
                  name="phone"
                  onChange={(event) => onChange(event)}
                  defaultValue={phone}
                  autoComplete="new-off"
                />
              </div>
              <div class="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Direccion
                </label>
                <input
                  type="text"
                  class="form-control border-primary rounded-pill"
                  placeholder="Ingresar telefono"
                  name="address"
                  onChange={(event) => onChange(event)}
                  defaultValue={address}
                  autoComplete="new-off"
                />
              </div>
              <div class="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Rol
                </label>
                <select
                  class="form-select border-primary rounded-pill"
                  aria-label="Default select example"
                  name="rol"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>{roles.length == 0? "Seleccione un rol..." : roles[0].description}</option>
                  <option value={1}>Administrador</option>
                  <option value={2}>Cliente</option>
                </select>
                {alert ? (
                  form.rol.trim() == "" ? (
                    <span className="text-danger">
                      Seleccione un rol disponible
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              {
                data
                ? <div className="col-md-12 mb-2 text-center">
                  Escribe una nueva contraseña para tu usuario
                </div>
                : ""
              }
                
              <div class="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Contraseña
                </label>
                <div className="input-group mb-3">
                  <label
                    className={
                      actions.showPassword
                        ? "btn input-group-text rounded-pill bg-primary border-primary text-white rounded-end bi bi-eye"
                        : "btn input-group-text rounded-pill bg-primary border-primary text-white rounded-end bi bi-eye-slash"
                    }
                  >  <button
                  className="d-none"
                  name="showPassword"
                  onClick={(event) => onClick(event)}
                ></button>
                </label>
                  <input
                    type={actions.showPassword ? "text" : "password"}
                    className="form-control border-primary rounded-pill rounded-start"
                    placeholder="Escriba una contraseña valida"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                    name="password"
                    onChange={(event) => onChange(event)}
                    required
                    autoComplete="off"
                  />
                </div>
                {alert ? (
                  form.password.trim() == "" ? (
                    <span className="text-danger">Ingrese una contraseña</span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div class="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" class="form-label">
                  Repita su contraseña
                </label>
                <div className="input-group mb-3">
                  <label
                    className={
                      actions.showPassword
                        ? "btn input-group-text rounded-pill bg-primary border-primary text-white rounded-end bi bi-eye"
                        : "btn input-group-text rounded-pill bg-primary border-primary text-white rounded-end bi bi-eye-slash"
                    }
                  >  <button
                  className="d-none"
                  name="showPassword"
                  onClick={(event) => onClick(event)}
                ></button>
                </label>
                  <input
                    type={actions.showPassword ? "text" : "password"}
                    className="form-control border-primary rounded-pill rounded-start"
                    placeholder="Repita su contraseña"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                    name="confirmation_password"
                    onChange={(event) => onChange(event)}
                    required
                    autoComplete="off"
                  />
                </div>
                {alert ? (
                  form.confirmation_password.trim() == "" ? (
                    <span className="text-danger">Repita su contraseña</span>
                  ) : form.confirmation_password !== form.password ? (
                    <span className="text-danger">
                      Su contraseña no es igual
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-end">
              {data ? (
                <button
                  className="btn btn-primary rounded-pill me-2"
                  name="update_user"
                  onClick={(event) => onClick(event, data)}
                  disabled={loading}
                >
                   {
                    loading
                    ? <div>
                      <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> Aplicando cambios...
                    </div>
                    : "Aplicar cambios"
                  }
                </button>
              ) : (
                <button
                  className="btn btn-primary rounded-pill me-2"
                  name="add_user"
                  onClick={(event) => onClick(event)}
                  disabled={loading}
                >
                  {
                    loading
                    ? <div>
                      <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> Guardando...
                    </div>
                    : "Guardar"
                  }
                </button>
              )}
              <button
                class="btn btn-secondary rounded-pill me-1"
                name="close"
                onClick={(event) => onClick(event)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUser;
