import logo from './logo.svg';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import { 
  Route, 
  BrowserRouter as Router, 
  Routes 
} from 'react-router-dom';
import Body from './components/layout/Body';
import Login from './components/layout/login/Login';
import Dashboard from './components/dashboard/dashboard';
import Usuarios from './components/dashboard/layout/usuarios/Usuarios';
import Articulos from './components/dashboard/layout/Articulos/Articulos';
import Promotions from './components/dashboard/layout/promotions/Index';
import Orders from './components/dashboard/layout/Orders/Orders';
import OrderDetails from './components/dashboard/layout/Orders/detalles/OrderDetails';

//Components

function App() {
  return (
    <AuthProvider>
    <Router>
        <Routes>

          {/* ROUTE ACCES DASHBOARD*/}

          <Route exact path='/' element={<Body><Login></Login></Body>} />
          <Route exact path='/login' element={<Body><Login></Login></Body>} />

          {/* ROUTE DASHBOARD*/}

          <Route exact path='/dashboard/usuarios' element={<Dashboard><Usuarios/></Dashboard>} />

          <Route exact path='/dashboard/productos' element={<Dashboard><Articulos/></Dashboard>} />
          
          <Route exact path='/dashboard/pedidos' element={<Dashboard><Orders/></Dashboard>} />
          
          <Route exact path='/dashboard/pedidos/detalles/:id' element={<OrderDetails/>} />
          
          <Route exact path='/dashboard/catalogo' element={<Dashboard><Promotions /></Dashboard>} />

          <Route exact path='/dashboard/coeficientes' element={<Dashboard></Dashboard>} />

        </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
