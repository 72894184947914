const Filtros = ({
  initialStateFilter,
  setInitialStateFilter,
  data,
  params,
  setParams,
  steps,
  setSteps,
  fetch,
  onClick,
  controller,
  //setController
}) => {
  const onChange = (e) => {
    switch (e.target.name) {
      case "subrubros":
      case "rubros":
        {
          setParams({
            ...params,
            [e.target.name]: [e.target.value],
          });
        }
        break;
      case "status":
        {
          setParams({
            ...params,
            [e.target.name]: e.target.value,
          });

          setSteps(steps + 1);
        }
        break;
      case "search":
        {

          if (e.target.value.length < initialStateFilter.search.length) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value == "") {
              return setParams({
                ...params,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3) {

              return setParams({
                ...params,
                [e.target.name]: e.target.value,
              });
            }
          }
        }
        break;
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <label className="btn btn-primary rounded-pill">
          <i class="bi bi-plus-lg"></i>Agregar producto
          <button
            className="d-none"
            name="modal_art"
            onClick={(event) => onClick(event, null)}
          ></button>
        </label>
      </div>
      <div className="col-md-3">
        <div class="input-group mb-3">
          <input
            type="text"
            class=" border-primary rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
            placeholder="Busca tu reporte según el nombre del producto o el codigo"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            autoComplete="off"
            name="search"
            onChange={(event) => onChange(event)}
          />
          <button
            class="input-group-text btn btn-primary px-2 p-0 border-primary text-white rounded-pill rounded-start"
            id="basic-addon2"
          >
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
      <div className="col-md-3 mb-3">
        <select
          class="form-select rounded-pill border-primary"
          aria-label="Default select example"
          name="status"
          onChange={(event) => onChange(event)}
        >
          <option selected>Selecciona un estado...</option>
          <option value={1}>Activos</option>
          <option value={2}>Inactivos</option>
        </select>
      </div>
      <div className="col-md-3 mb-3">
        <select
          class="form-select rounded-pill border-primary"
          aria-label="Default select example"
          name="rubros"
          onChange={(event) => onChange(event)}
        >
          <option selected>Selecciona un rubro...</option>
          {data.map((rubro) => (
            <option value={rubro.name}>{rubro.name}</option>
          ))}
        </select>
      </div>
      {params.rubros.length !== 0 ? (
        <div className="col-md-3 mb-3">
          <select
            class="form-select rounded-pill border-primary"
            aria-label="Default select example"
            name="subrubros"
            onChange={(event) => onChange(event)}
          >
            <option selected>Selecciona un sub rubro...</option>
            {data.map((rubro) => {
              if (rubro.name == params.rubros[0]) {
                return (
                  <>
                    {rubro.subrubros.map((subrubro) => (
                      <option value={subrubro.name}>{subrubro.name}</option>
                    ))}
                  </>
                );
              }
            })}
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Filtros;
