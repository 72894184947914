import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { apiRest } from "../../../../../hooks/useRest";

const Tabla = ({
  setSteps,
  steps,
  setLoading,
  loading,
  setSelect,
  setModal,
  nextPage,
  prevPage,
  links,
  data,
}) => {
  const navigate = useNavigate();

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "modal_products":
        {
          setSelect(value);
          setModal(true);
        }
        break;
    }
  };

  const onChange = async (e, value) => {
    switch (e.target.name) {
      case "change_status":
        {
          let status = e.target.value;

          //recreate form
          let formData = new FormData();
          formData.append("id", value.id);
          formData.append("status", status);

          //send api rest
          const response = await apiRest({
            url: "/Auth/Pedidos/Actualizar",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          //setModalUser(false);
          setSteps(steps + 1);
        }
        break;
    }
  };

  const Totals = (select, type_payments) => {
    let cantidad = 0;
    let precio = 0;

    select.map((product) => {
      cantidad = cantidad + product.quantity;
      precio = product.quantity * parseFloat(product.price_unit) + precio;
    });

    return {
      cantidad: cantidad,
      precio: precio + (precio / 100) * type_payments.coefficient,
    };
  };

  console.log(data);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex justify-content-end w-100 my-2">
          <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
            <ul class="pagination bg-transparent">
              <li class="page-item">
                {data ? (
                  <button
                    className="btn btn-outline-warning me-1 fs-8"
                    onClick={prevPage}
                    disabled={data.current_page == 1 ? true : false}
                  >
                    Anterior
                  </button>
                ) : (
                  ""
                )}
              </li>

              {data
                ? data.links.map((item, index) => {
                    if (
                      (item.label !== "&laquo; Previous" &&
                        item.label !== "Next &raquo;" &&
                        item.active == true) ||
                      item.label == 1 ||
                      item.label == data.current_page - 1 ||
                      item.label == data.current_page + 1 ||
                      item.label == data.current_page + 2 ||
                      item.label == data.per_page ||
                      item.label == data.last_page
                    ) {
                      return (
                        <li
                          style={{ borderRadius: "5px" }}
                          className={item.active == true ? "fs-8" : "fs-8"}
                        >
                          <button
                            style={{ borderRadius: "5px" }}
                            className={
                              item.active == true
                                ? "btn btn-warning me-1 fs-8"
                                : "btn btn-outline-warning me-1 fs-8"
                            }
                            onClick={(event) => links(event, item.url)}
                          >
                            {item.label}
                          </button>
                        </li>
                      );
                    }
                  })
                : ""}
              <li class="page-item">
                {data ? (
                  <button
                    className="btn btn-outline-warning me-1 fs-8 "
                    onClick={nextPage}
                    disabled={
                      data.current_page == data.last_page ? true : false
                    }
                  >
                    Siguiente
                  </button>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {data.data.map((order) => (
        <div className={"col-md-12 mb-5 shadow "}>
          <div className="row p-1">
            <div className="col-md-12 d-flex justify-content-between">
              <div></div>
              <div>
                <label className="btn btn-outline-dark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-printer"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                  </svg>
                  <button
                    className="d-none"
                    onClick={
                      (event) =>
                        window.open(
                          "https://api.popeyemayorista.com.ar/dashboard/pedidos/detalles/" +
                            order.id,
                          "_blank"
                        )
                      //</label>navigate("/dashboard/pedidos/detalles/"+order.id)
                    }
                  ></button>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <ul class="list-group">
                <li class="list-group-item border-0 d-flex">
                  <div className="fw-bold">
                    <span className="fw-normal">CODIGO: </span>
                    {order.code}
                  </div>
                  <div className="ms-2 fw-bold d-flex">
                    <span className="fw-normal">ESTADO: </span>
                    <select
                      class="form-select ms-2"
                      aria-label="Default select example"
                      name="change_status"
                      onChange={(event) => onChange(event, order)}
                    >
                      <option selected>{order.status.name}</option>
                      {order.status.id != 1 ? (
                        <option value="1">Activo</option>
                      ) : (
                        ""
                      )}
                      {order.status.id != 2 ? (
                        <option value="3">Inactivo</option>
                      ) : (
                        ""
                      )}
                      {order.status.id != 3 ? (
                        <option value="3">Eliminado</option>
                      ) : (
                        ""
                      )}
                      {order.status.id != 4 ? (
                        <option value="4">Pausado</option>
                      ) : (
                        ""
                      )}
                      {order.status.id != 5 ? (
                        <option value="5">Recibimos tu pedido</option>
                      ) : (
                        ""
                      )}
                      {order.status.id != 6 ? (
                        <option value="6">Cancelado</option>
                      ) : (
                        ""
                      )}
                      {order.status.id != 7 ? (
                        <option value="7">Demorado</option>
                      ) : (
                        ""
                      )}
                      {order.status.id != 8 ? (
                        <option value="8">Preparando pedido</option>
                      ) : (
                        ""
                      )}

                      
                      {order.status.id != 9 ? (
                        <option value="8">Comienzo de facturacion</option>
                      ) : (
                        ""
                      )}

                      {order.status.id != 10 ? (
                        <option value="9">Facturacion final</option>
                      ) : (
                        ""
                      )}

{order.status.id != 10 ? (
                        <option value="9">Envío en camino</option>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex">
                  <div className="fw-bold me-2">
                    <span className="fw-normal">E-MAIL: </span>
                    {order.users.email}
                  </div>
                  <div className="fw-bold">
                    <span className="fw-normal">NOMBRE: </span>
                    {order.users.name + " " + order.users.last_name}
                  </div>
                </li>
                <li class="list-group-item border-0">
                  <div className="">OBSERVACIONES: </div>
                  <div className="fw-bold">{order.observation}</div>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <li class="list-group-item border-0 d-flex">
                  <div className="fw-bold">
                    <span className="fw-normal">PRODUCTOS: </span>
                    {order.reports.lenght}
                  </div>
                  <label className="p-0 m-0 px-2 ms-2 btn btn-dark">
                    Ver productos
                    <button
                      className="d-none"
                      name="modal_products"
                      onClick={(event) => onClick(event, order)}
                    ></button>
                  </label>
                </li>
                <li class="list-group-item border-0 d-flex">
                  <div className="fw-bold">
                    <span className="fw-normal">METODO DE ENVIO: </span>
                    {order.shipping_methods.description}
                  </div>
                </li>
                {order.type_payments.map((payment) => (
                  <li class="list-group-item border-0">
                    <div className="fw-bold mb-3">
                      <span className="fw-normal">METODO DE PAGO: </span>
                      {payment.payment_method}
                    </div>
                    <div className="fw-bold mb-3">
                      <span className="fw-normal">TOTAL A PAGAR: </span>$
                      {(
                        Totals(order.reports, payment).precio 
                      ).toFixed(2)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-12 mb-2">
              <div className={order.type == "web" ? "btn btn-danger w-100 text-white" : "btn btn-primary w-100 text-white"}>
                <span className="fw-normal">FECHA DE PEDIDO: </span>
                {format(new Date(order.created_at), "d/M/yyyy").toString()}
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="col-md-12">
        <div className="d-flex justify-content-end w-100 my-2">
          <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
            <ul class="pagination bg-transparent">
              <li class="page-item">
                {data ? (
                  <button
                    className="btn btn-outline-warning me-1 fs-8"
                    onClick={prevPage}
                    disabled={data.current_page == 1 ? true : false}
                  >
                    Anterior
                  </button>
                ) : (
                  ""
                )}
              </li>

              {data
                ? data.links.map((item, index) => {
                    if (
                      (item.label !== "&laquo; Previous" &&
                        item.label !== "Next &raquo;" &&
                        item.active == true) ||
                      item.label == 1 ||
                      item.label == data.current_page - 1 ||
                      item.label == data.current_page + 1 ||
                      item.label == data.current_page + 2 ||
                      item.label == data.per_page ||
                      item.label == data.last_page
                    ) {
                      return (
                        <li
                          style={{ borderRadius: "5px" }}
                          className={item.active == true ? "fs-8" : "fs-8"}
                        >
                          <button
                            style={{ borderRadius: "5px" }}
                            className={
                              item.active == true
                                ? "btn btn-warning me-1 fs-8"
                                : "btn btn-outline-warning me-1 fs-8"
                            }
                            onClick={(event) => links(event, item.url)}
                          >
                            {item.label}
                          </button>
                        </li>
                      );
                    }
                  })
                : ""}
              <li class="page-item">
                {data ? (
                  <button
                    className="btn btn-outline-warning me-1 fs-8 "
                    onClick={nextPage}
                    disabled={
                      data.current_page == data.last_page ? true : false
                    }
                  >
                    Siguiente
                  </button>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabla;
