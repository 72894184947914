import { useContext } from "react"
import { authContext } from "../../context/AuthContext"
//
import { Navigate } from "react-router-dom";
//
import NavBar from "./layout/NavBar/NavBar"

const Dashboard = ({children}) => {
  const usuario = useContext(authContext);


  if(!usuario.data){
    return <Navigate to={"/login"} />
  }

  return (
    <>
      <NavBar />
      <main>
        {children}
      </main>
    </>
  )
}

export default Dashboard