import axios from "axios";

//'https://devtesting.gq/backend/public/api'
//'https://api.popeyemayorista.com.ar/backend/public/api'
//'http://127.0.0.1:8000/api

let clientAxios = axios.create({
  baseURL: 'https://api.popeyemayorista.com.ar/backend/public/api'
});

clientAxios.defaults.headers.common["Content-Type"] = "application/json";

clientAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  const adress = localStorage.getItem("adress");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers.adress = adress ? `${adress}` : "";
  return config;
});

export default clientAxios;