import Papa from "papaparse";
import { useState } from "react";
import { apiRest } from "../hooks/useRest";
import CustomAlerts from "../alerts/CustomAlerts";

// Allowed extensions for input file
const allowedExtensions = ["csv"];

export const UserCsv = () => {

  let contador = 1;
  // This state will store the parsed data
  const [data, setData] = useState([]);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState("");

  // It will store the file uploaded by the user
  const [file, setFile] = useState("");
  const [loadingRubros, setLoadingRubros] = useState(false);
  const [loadingSubRubros, setLoadingSubRubros] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [loadingArticles_2, setLoadingArticles_2] = useState(false);
  const [loadingMarcas, setLoadingMarcas] = useState(false);

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      let inputFile = [];
      let files = [];

      for (let i = 0; i < e.target.files.length; i++) {
        //const element = array[i];
        const inputFile = e.target.files[i];

        const fileExtension = inputFile?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
          setError("Please input a csv file");
          return;
        }

        files[0] = e.target.files[i];
      }

      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);
        if (files[i]) {
          handleSubmitFile(files[i]);
        }
        //handleSubmitFile(files[i]); 
      }

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
    }

  };

  const handleSubmitFile = (file) => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;

      uploadedFile(file, parsedData, setLoadingArticles);

    };

    reader.readAsText(file)
  }

  const uploadedFile = async (file, content, setLoading) => {
    console.log(content);
    console.log(file);

    setLoading(true);

    //recreate form
    let contenido = [];

    content.map((c) => {
      if (
        c.hasOwnProperty('id_clientes') &&
        c.hasOwnProperty('email') &&
        c.hasOwnProperty('id_clusuarios') &&
        c.hasOwnProperty('nombre') &&
        c['email'].trim() != "" &&
        c['id_clientes'].trim() != ""
      ) {
        contenido.push(c)
      }
    })

    let formData = {
      usuarios: contenido
    };

    console.log(formData);

    //send api rest
    const response = await apiRest({
      url: "/Auth/Usuarios/Csv",
      type: "POST",
      form: formData,
      setLoading: setLoading,
    });

    if (response.status == 200 || response.status == 201) {
    } else {
      if (typeof response.data.msg === "string") {
        return CustomAlerts({
          msg: response.data.msg,
          title: response.data.msg,
          type: "error",
          size: "xl"
        })
      }

      Object.keys(response.data.msg).map((key) => {
        CustomAlerts({
          msg: response.data.msg[key][0],
          title: response.data.msg[key][0],
          type: "error",
          size: "xl"
        })
      });
    }
  }

  return (
    <div className="d-flex">
      <label title="Carga tus archivos" className="ms-3 btn btn-outline-success py-2" style={{ fontSize: "12px" }}>
        {
          loadingRubros || loadingSubRubros || loadingArticles || loadingMarcas || loadingArticles_2
            ? <div className="d-flex justify-content-center align-items-center">
              <div class="spinner-border spinner-border-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              Subiendo archivos...
            </div>
            : <div className=" d-flex justify-content-center align-items-center">
              Usuario CSV
              <i class="ms-2 bi bi-cloud-arrow-down"></i>
              <input
                onChange={handleFileChange}
                id="csvInput"
                name="file"
                type="File"
                className="d-none"
                multiple
              />
            </div>
        }
        <div className="text-danger" style={{ fontSize: "12px" }}>
          {error ? error : data.map((col,
            idx) => <div key={idx}>{col}</div>)}
        </div>
      </label>
    </div>
  );
}


export const RestorePasswordApi = () => {

  let contador = 1;
  // This state will store the parsed data
  const [data, setData] = useState([]);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState("");

  // It will store the file uploaded by the user
  const [file, setFile] = useState("");
  const [loadingRubros, setLoadingRubros] = useState(false);
  const [loadingSubRubros, setLoadingSubRubros] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [loadingArticles_2, setLoadingArticles_2] = useState(false);
  const [loadingMarcas, setLoadingMarcas] = useState(false);

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      let inputFile = [];
      let files = [];

      for (let i = 0; i < e.target.files.length; i++) {
        //const element = array[i];
        const inputFile = e.target.files[i];

        const fileExtension = inputFile?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
          setError("Please input a csv file");
          return;
        }

        files[0] = e.target.files[i];
      }

      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);
        if (files[i]) {
          handleSubmitFile(files[i]);
        }
        //handleSubmitFile(files[i]); 
      }

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
    }

  };

  const handleSubmitFile = (file) => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;

      uploadedFile(file, parsedData, setLoadingArticles);

    };

    reader.readAsText(file)
  }

  const uploadedFile = async (file, content, setLoading) => {
    console.log(content);
    console.log(file);

    setLoading(true);

    //recreate form
    let contenido = [];

    content.map((c) => {
      if (
        c.hasOwnProperty('password') &&
        c.hasOwnProperty('email') &&
        c.hasOwnProperty('remember_password') &&
        c['email'].trim() != "" &&
        c['password'].trim() != ""
      ) {
        contenido.push(c)
      }
    })

    let formData = {
      usuarios: contenido
    };

    console.log(formData);

    //send api rest
    const response = await apiRest({
      url: "/Auth/Usuarios/Restore/Api",
      type: "POST",
      form: formData,
      setLoading: setLoading,
    });


  }

  return (
    <div className="d-flex">
      <label title="Carga tus archivos" className="ms-3 btn btn-outline-success py-2" style={{ fontSize: "12px" }}>
        {
          loadingRubros || loadingSubRubros || loadingArticles || loadingMarcas || loadingArticles_2
            ? <div className="d-flex justify-content-center align-items-center">
              <div class="spinner-border spinner-border-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              Subiendo archivos...
            </div>
            : <div className=" d-flex justify-content-center align-items-center">
              Restaurar Usuarios API
              <i class="ms-2 bi bi-cloud-arrow-down"></i>
              <input
                onChange={handleFileChange}
                id="csvInput"
                name="file"
                type="File"
                className="d-none"
                multiple
              />
            </div>
        }
        <div className="text-danger" style={{ fontSize: "12px" }}>
          {error ? error : data.map((col,
            idx) => <div key={idx}>{col}</div>)}
        </div>
      </label>
    </div>
  );
}


