import { useState } from "react";
import useOrders from "../../../../hooks/useOrders";
import Filtros from "./filters/Filters";
import ModalProducts from "./modals/ModalProducts";
import Tabla from "./table/Tabla";

const Orders = () => {
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [steps, setSteps] = useState(0);
  const [modal, setModal] = useState(false);

  //Parametros
  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });

  const [params, setParams] = useState({
    url: "/Auth/Pedidos",
    search: "",
    //status_id: 1,
    start_date: "",
    end_date: "",
    pagination: 50,
  });

  const { order_data, order_message, order_isLoading } = useOrders(
    params.url,
    params,
    steps
  );

  const onClick = (e, value) => {};

  const onChange = (e, value) => {};

  const nextPage = (e) => {
    setParams({
      ...params,
      url: order_data.next_page_url,
    });
  };
  const prevPage = (e) => {
    setParams({
      ...params,
      url: order_data.prev_page_url,
    });
  };
  const links = (e, value) => {
    setParams({
      ...params,
      url: value,
    });
  };

  return (
    <div className="container-md">
      <div className="py-5">
        <h1>Pedidos</h1>
      </div>

      <div className="my-5">
        <Filtros
          initialStateFilter={initialStateFilter}
          setInitialStateFilter={setInitialStateFilter}
          params={params}
          setParams={setParams}
          steps={steps}
          setSteps={setSteps}
          onClick={onClick}
        />
      </div>

      <div className="my-5">
        {order_data ? (
          <Tabla
            onClick={onClick}
            onChange={onChange}
            nextPage={nextPage}
            prevPage={prevPage}
            links={links}
            setModal={setModal}
            setSteps={setSteps}
            steps={steps}
            setLoading={setLoading}
            loading={loading}
            select={select}
            setSelect={setSelect}
            data={order_data}
          />
        ) : (
          <div className="border-0 min-vh-100 d-flex justify-content-center align-items-center ">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>

      {modal ? <ModalProducts select={select} setModal={setModal} /> : ""}
    </div>
  );
};

export default Orders;
