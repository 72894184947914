import { useState } from "react";
import { apiRest } from "../../../../../hooks/useRest";

const ModalPromotion = ({ setModal, select, setSelect, setSteps, steps }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    image: "",
    image_url: select ? select.url : "",
    name: select ? select.name : "",
    status: select ? select.status_id : "",
  });

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "close":
        {
          setModal(false);
        }
        break;
      case "guardar":
        {
          //recreate form
          let formData = new FormData();
          if(form.image != "") formData.append("image", form.image);
          formData.append("name", form.name);
          formData.append("status", form.status == "" ? 1 : form.status);

          if (select) {
            formData.append("id", select.id);

            //send api rest
            const response = await apiRest({
              url: "/Auth/Promociones/Actualizar",
              type: "POST",
              form: formData,
              setLoading: setLoading,
            });

            setSteps(steps+1)
            return setModal(false);
          }

          //send api rest
          const response = await apiRest({
            url: "/Auth/Promociones/Crear",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          setSteps(steps+1)
          return setModal(false);
        }
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "image":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            image_url: URL.createObjectURL(e.target.files[0]),
          });
        }
        break;
      case "status":
      case "name":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  return (
    <div
      className="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        className="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div className="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Añadir nuevo banner</h1>
              <span className="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                className="border-0 bg-transparent "
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="body p-3">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div
                  className="bg-secondary bg-opacity-50 d-flex justify-content-center overflow-hidden"
                  style={{ height: "200px", borderRadius: "20px" }}
                >
                  <img
                    className="img-fluid"
                    src={form.image_url ? form.image_url : ""}
                    alt=""
                  />
                </div>
                <label for="exampleFormControlInput1" className="form-label">
                  Sube una imagen.
                </label>
                <input
                  type="file"
                  className="form-control rounded-pill border-primary"
                  placeholder=""
                  name="image"
                  onChange={(event) => onChange(event)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill border-primary"
                  placeholder="Ingresa un nombre a tu banner"
                  name="name"
                  defaultValue={form.name}
                  onChange={(event) => onChange(event)}
                />
              </div>
              {select ? (
                ""
              ) : (
                <div className="col-md-6 mb-3 ">
                  {" "}
                  <label for="exampleFormControlInput1" className="form-label">
                    Estado
                  </label>
                  <select
                    className="form-select rounded-pill border-primary"
                    aria-label="Default select example"
                    name="status"
                    onChange={(event) => onChange(event)}
                  >
                    <option value={1}>Activo</option>
                    <option value={2}>Inactivo</option>
                  </select>
                </div>
              )}
            </div>
          </div>
          <div className="footer bg-white p-4">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary rounded-pill me-2"
                name="guardar"
                onClick={(event) => onClick(event)}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPromotion;
