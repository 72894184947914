import CustomAlerts from "../alerts/CustomAlerts";
import clientAxios from "../config/ConfigAxios";

export const apiRest = async ({
  url,
  type,
  form,
  setLoading
}) => {
  let response = ""

  switch (type) {
    //Insert 
    case "POST": {
      response = await clientAxios({
        method: "post",
        url: url,
        data: form
      }).then((result) => {
        setLoading(false);
        CustomAlerts({
          size: "xl",
          type: "success",
          title: "Exitoso",
          msg: result.data.msg
        });
        return result
      }).catch((error) => {
        setLoading(false);
        if (error.response) {
          return error.response;
        } else if (error.request) {
          return error.request;
        } else {
          return error.message;
        }
      })
    } break;
    //Update
    case "PUT": {
      response = await clientAxios({
        method: "post",
        url: url,
        data: form
      }).then((result) => {
        setLoading(false);
        return CustomAlerts({
          size: "xl",
          type: "success",
          title: "Exitoso",
          msg: result.data.msg
        });
      }).catch((result) => {
        setLoading(false);
        return CustomAlerts({
          msg: result.response.data.error,
          title: "ERROR",
          type: "error",
          size: "xl"
        })
      })
    } break;
  }

  return response
}