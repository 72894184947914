import Papa from "papaparse";
import { useEffect, useState } from "react";
import { apiRest } from "../hooks/useRest";

// Allowed extensions for input file
const allowedExtensions = ["csv"];

export const ReadCsv = () => {

  let contador = 1;
  // This state will store the parsed data
  const [data, setData] = useState([]);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState("");

  // It will store the file uploaded by the user
  const [file, setFile] = useState("");
  const [loadingRubros, setLoadingRubros] = useState(false);
  const [loadingSubRubros, setLoadingSubRubros] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [loadingArticles_2, setLoadingArticles_2] = useState(false);
  const [loadingMarcas, setLoadingMarcas] = useState(false);

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      let inputFile = [];
      let files =[];

      for (let i = 0; i < e.target.files.length; i++) {
        //const element = array[i];
        const inputFile = e.target.files[i];

        const fileExtension = inputFile?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
          setError("Please input a csv file");
          return;
        }




        switch (inputFile.name.split(".")[0]) {
          case 'marcas': {
            files[0] = e.target.files[i];
            /*
            if (contador == 1) {
              handleSubmitFile(inputFile);

              contador = contador + 1;
              handleFileChange(e);
            }
            */
          } break;
          case 'rubros': {
            files[1] = e.target.files[i];
            /*
            if (contador == 2) {
              handleSubmitFile(inputFile);

              contador = contador + 1;
              handleFileChange(e);
            }
            */
          } break;
          case 'subrubros': {
            files[2] = e.target.files[i];
            /*
            if (contador == 3) {
              handleSubmitFile(inputFile);

              contador = contador + 1;
              handleFileChange(e);
            }
            
            */
          } break;
          case 'articulos': {
            files[3] = e.target.files[i];
            /*
            if (contador == 4) {
              handleSubmitFile(inputFile);

              contador = contador + 1;
            }
            
            */
          } break;
        }


      }

      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);
        if(files[i]){
          handleSubmitFile(files[i]); 
        }
        //handleSubmitFile(files[i]); 
      }

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
    }

  };

  const handleSubmitFile = (file) => {

    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      //const columns = Object.keys(parsedData[0]);

      /* Si csv es muy grande dividir en dos */
      if (parsedData.length > 8000) {
        let mitad = Math.floor(parsedData.length / 2);

        let inicio = parsedData.slice(0, mitad);
        let final = parsedData.slice(mitad); // si no se indica el índice final, se usa la longitud del array como referencia

        uploadedFile(file, inicio, setLoadingArticles);
        uploadedFile(file, final, setLoadingArticles_2);
      } else {
        uploadedFile(file, parsedData, setLoadingArticles);
      }
    };

    reader.readAsText(file)
  }

  const uploadedFile = async (file, content, setLoading) => {
    console.log(content);
    console.log(file);

    switch (file.name.split(".")[0]) {
      case "articulos": {
        console.log("articulos");
        setLoading(true);
        //recreate form
        //let formData = new FormData();   
        //formData.append("id", value.id);
        let contenido = [];

        content.map((c) => {
          if (
            c.hasOwnProperty('cod_fab') &&
            c.hasOwnProperty('des_art') &&
            c.hasOwnProperty('id_articulo') &&
            c.hasOwnProperty('id_lineas') &&
            c.hasOwnProperty('id_rubros') &&
            c.hasOwnProperty('id_subrubros') &&
            c.hasOwnProperty('num_art') &&
            c.hasOwnProperty('order_num') &&
            c.hasOwnProperty('precio') &&
            c.hasOwnProperty('stock') &&
            c.hasOwnProperty('web') &&
            c.num_art.trim() != "" &&
            c.num_art.trim() != ""
          ) {
            contenido.push(c)
          }
        })

        let formData = {
          productos: contenido
        };

        //send api rest
        const response = await apiRest({
          url: "/Auth/Productos/File",
          type: "POST",
          form: formData,
          setLoading: setLoading,
        });
      } break;
      case "rubros": {
        console.log("rubros");

        setLoadingRubros(true);
        //recreate form
        //let formData = new FormData();
        //formData.append("id", value.id);
        let contenido = [];

        content.map((c) => {
          if (
            c.hasOwnProperty('id_rubros') &&
            c.hasOwnProperty('nom_rub') &&
            c.id_rubros.trim() != "" &&
            c.nom_rub.trim() != ""
          ) {
            contenido.push(c)
          }
        })

        let formData = {
          rubros: contenido
        };

        //send api rest
        const response = await apiRest({
          url: "/Auth/Rubros/File",
          type: "POST",
          form: formData,
          setLoading: setLoadingRubros,
        });
      } break;
      case "subrubros": {
        console.log("subrubros");

        setLoadingSubRubros(true);
        //recreate form
        //let formData = new FormData();
        //formData.append("id", value.id);
        let contenido = [];

        content.map((c) => {
          if (
            c.hasOwnProperty('id_subrubros') &&
            c.hasOwnProperty('nomsub') &&
            c.hasOwnProperty('id_rubros') &&
            c.id_subrubros.trim() != "" &&
            c.nomsub.trim() != "" &&
            c.id_rubros.trim() != ""
          ) {
            contenido.push(c)
          }
        })

        let formData = {
          subrubros: contenido
        };

        //send api rest
        const response = await apiRest({
          url: "/Auth/SubRubros/File",
          type: "POST",
          form: formData,
          setLoading: setLoadingSubRubros,
        });
      } break;
      case "marcas": {
        console.log("marcas");
        setLoadingMarcas(true);

        //recreate form
        //let formData = new FormData();
        //formData.append("id", value.id);
        let contenido = [];

        content.map((c) => {
          if (
            c.hasOwnProperty('id_lineas') &&
            c.hasOwnProperty('des_lin') &&
            c.id_lineas.trim() != "" &&
            c.des_lin.trim() != ""
          ) {
            contenido.push(c)
          }
        })

        let formData = {
          marcas: contenido
        };

        //send api rest
        const response = await apiRest({
          url: "/Auth/Marcas/File",
          type: "POST",
          form: formData,
          setLoading: setLoadingMarcas,
        });
      } break;
    }

  }
  
  return (
    <div className="d-flex">
      <label title="Carga tus archivos" className="ms-3 btn btn-outline-success py-2" style={{ fontSize: "12px" }}>
        {
          loadingRubros || loadingSubRubros || loadingArticles || loadingMarcas || loadingArticles_2
            ? <div className="d-flex justify-content-center align-items-center">
              <div class="spinner-border spinner-border-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              Subiendo archivos...
            </div>
            : <div className=" d-flex justify-content-center align-items-center">
              Sube tu archivo CSV
              <i class="ms-2 bi bi-cloud-arrow-down"></i>
              <input
                onChange={handleFileChange}
                id="csvInput"
                name="file"
                type="File"
                className="d-none"
                multiple
              />
            </div>
        }
        <div className="text-danger" style={{ fontSize: "12px" }}>
          {error ? error : data.map((col,
            idx) => <div key={idx}>{col}</div>)}
        </div>
      </label>
    </div>
  );
}

