import { useEffect, useState } from "react";
import CustomAlerts from "../alerts/CustomAlerts";
import clientAxios from "../config/ConfigAxios";

const useAuth = (url, parametros, steps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState(null); 
  const [data, setData] = useState(null);

  let params = {};

  Object.keys(parametros).map((p) => {
    if(p !== "date_range"){
      if(String(parametros[p]).trim() !== ""){
        params = {
          ...params,
          [p]: parametros[p] 
        }
      }
    } else {
      if(parametros[p].length !== 0){
        params = {
          ...params,
          [p]: parametros[p] 
        } 
      }
    }
    
  })



  const doAxios = async () => {
    const response = await clientAxios.get(url, { params })
    .then((result) => {
      setIsLoading(false);

      /*
      let array = [];

      if (result.data.hasOwnProperty('data')) {
       if(!result.data.data.length){
        Object.keys(result.data.data).map((key) => {
          array.push(result.data.data[key]);
        });
    
        result.data.data = array;
      }
      }
      */

      
      setData(result.data.data);
      return console.log("Autenticado");
      /* CustomAlerts({
        size: "xl",
        type: "success",
        title: "OK",
        msg: result.data.msg
      });
      */
    })
    .catch((result) => {
      setIsLoading(false);
      //setMessage(result.response.data.msg);
      return console.log("Sin autenticar");
      /*
      CustomAlerts({
        size: "xl",
        type: "error",
        title: "ERROR",
        msg: result.response.data.msg
      });
      */
    });

    

  }


  useEffect(() => {
    doAxios()
  },[steps, parametros])
  return {
    data,
    message,
    isLoading,
  }
}

export default useAuth