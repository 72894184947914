import { RestorePasswordApi, UserCsv } from "../../../../../utils/UsuarioUtils";

const Filtros = ({
  initialStateFilter,
  setInitialStateFilter,
  params,
  setParams,
  steps,
  setSteps,
  onClick
}) => {
  const onChange = (e) => {
    switch (e.target.name) {
      case "status":{
        setParams({
          ...params,
          [e.target.name]: e.target.value
        })

        setSteps(steps+1);
      } break;
      case "search":
        {
          if (e.target.value.length < initialStateFilter.search.length) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });

              setSteps(steps + 1);
            }
          }
        }
        break;
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-3 d-flex">
        <label className="btn btn-primary rounded-pill me-2">
        <i class="bi bi-plus-lg"></i>Agregar usuario
        <button className="d-none" 
            name="modal_user" onClick={(event) => onClick(event, null)}>
          </button>
        </label>
        <UserCsv />
        <div className="d-none">
        <RestorePasswordApi />
        </div>
      </div>
      <div className="col-md-3">
        <div class="input-group mb-3">
          <input
            type="text"
            class=" border-primary rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
            placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            name="search"
            onChange={(event) => onChange(event)}
            autocomplete="nope"
          />
          <button
            class="input-group-text btn btn-primary px-2 p-0 border-primary text-white rounded-pill rounded-start"
            id="basic-addon2"
          >
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
      <div className="col-md-3">
        <select class="form-select rounded-pill border-primary" aria-label="Default select example" 
            name="status"
            onChange={(event) => onChange(event)}>
          <option selected>Selecciona un estado...</option>
          <option value={1}>Activos</option>
          <option value={2}>Inactivos</option>
        </select>
      </div>
    </div>
  );
};

export default Filtros;
