import CustomAlerts from "../alerts/CustomAlerts";
import clientAxios from "../config/ConfigAxios";

export const Access = async (dataForm, setLoadingBtn) => {
  setLoadingBtn(true);

  const response = await clientAxios({
    method: "post",
    url: '/Login',
    data: dataForm
  }).then((result) => {
    setLoadingBtn(false);
    localStorage.setItem("token", result.data.data);
    CustomAlerts({
      size: "xl",
      type: "success",
      title: "OK",
      msg: result.data.msg
    });
  
    return window.location.href = '/dashboard/usuarios'; 
  }).catch((result) => {
    setLoadingBtn(false); 
    return CustomAlerts({
      size: "xl",
      type: "error",
      title: "Error",
      msg: result.response.data.msg
    });
  })
}