import { useEffect, useState } from "react";
import CustomAlerts from "../alerts/CustomAlerts";
import clientAxios from "../config/ConfigAxios";

const useOrders = (url, parametros, steps) => {
  const [order_isLoading, setIsLoading] = useState(true);
  const [order_message, setMessage] = useState(null); 
  const [order_data, setData] = useState(null);

  let params = {};

  Object.keys(parametros).map((p) => {
    if(p !== "date_range" || p !== "rubros" || p !== "subrubros"){
      if(String(parametros[p]).trim() !== ""){
        params = {
          ...params,
          [p]: parametros[p] 
        }
      }
    } else {
      if(parametros[p].length !== 0){
        params = {
          ...params,
          [p]: parametros[p] 
        } 
      }
    }
    
  })



  const doAxios = async () => {
    const response = await clientAxios.get(url, { params })
    .then((result) => {
      setIsLoading(false);

      console.log(result.data);

      //
      let arrayResult = [];
      
      if(!result.data.data.hasOwnProperty("data")){
        return result.data
      }
  
      if (!result.data.data.data.length) {
        Object.keys(result.data.data.data).map((key) => {
          arrayResult.push(result.data.data.data[key]);
        });
    
        result.data.data.data = arrayResult;
      }
      
     
      return  setData(result.data.data);
      /* CustomAlerts({
        size: "xl",
        type: "success",
        title: "OK",
        msg: result.data.msg
      });
      */
    })
    .catch((result) => {
      setIsLoading(false);
      //setMessage(result.response.data.msg);
      return console.log("");
      /*
      CustomAlerts({
        size: "xl",
        type: "error",
        title: "ERROR",
        msg: result.response.data.msg
      });
      */
    });

    

  }


  useEffect(() => {
    doAxios()
  },[steps, parametros])
  return {
    order_isLoading,
    order_message,
    order_data,
  }
}

export default useOrders