import { useContext } from "react";
import CustomAlerts from "../../../../alerts/CustomAlerts";
import clientAxios from "../../../../config/ConfigAxios";
import { authContext } from "../../../../context/AuthContext";

const NavBar = () => {
  const usuario = useContext(authContext);
  const {data } = usuario;

  const cerrarSesion = async (e, value) => {
    sessionStorage.clear()
    const response = await clientAxios.get('/Auth/Logout');

    CustomAlerts({
      msg: "",
      title: "Cerrando sesion...",
      type: "success",
      size: "xl"
    })
    
    return window.location.href = '/'; 
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-primary bg-primary">
      <div className="container-fluid">
        <h1>
          <a className="navbar-brand  text-white fs-2 p-3" href="#">
            Popeye!
          </a>
        </h1>
        <button
          className="navbar-toggler text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="bi bi-list"></i>
        </button>
        <div className="collapse navbar-collapse text-white" id="navbarNav">
          <ul className="navbar-nav text-white">
            <li class="nav-item fs-5">
              <a class="nav-link link-light text-nowrap" href="/dashboard/usuarios">
                <i class="bi bi-people me-1" style={{fontSize: "18px"}}></i>
                Usuarios
              </a>
            </li>
            <li class="nav-item fs-5 text-nowrap">
              <a class="nav-link link-light" href="/dashboard/productos">
                <i class="bi bi-cart me-1" style={{fontSize: "18px"}}></i>
                Productos
              </a> 
            </li>
            <li class="nav-item fs-5 text-nowrap">
              <a class="nav-link link-light" href="/dashboard/catalogo">
                <i class="bi bi-journal-check me-1" style={{fontSize: "18px"}}></i>
                Catalogo y Promociones
              </a>
            </li>
            <li class="nav-item fs-5 text-nowrap">
              <a class="nav-link link-light" href="/dashboard/coeficientes">
                <i class="bi bi-journal-check me-1" style={{fontSize: "18px"}}></i>
                Coeficientes
              </a>
            </li>
            <li class="nav-item fs-5 text-nowrap">
              <a class="nav-link link-light" href="/dashboard/pedidos">
                <i class="bi bi-journal-check me-1" style={{fontSize: "18px"}}></i>
                Pedidos
              </a>
            </li>
          </ul>
          <div className="navbar-text w-100 d-md-flex justify-content-end">
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle fs-5"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-person-circle me-2"></i>
                {data.name}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li className="text-nowrap">
                  <label className="btn btn-outline-primary border-0 p-2 m-0 fs-5">
                    <i class="bi bi-power me-2"></i>
                    Cerrar sesion
                    <button className="d-none"
                    onClick={(event) => cerrarSesion(event)}></button>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
