const ModalProducts = ({
  select,
  setModal
}) => {

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "close":{
        setModal(false);
      }break;
    }
  }



  const Totals = (products, type_payments) => {
    let cantidad = 0;
    let precio = 0

    products.map((product) => {
      let descuento = 0;
      let precio_unit =  parseFloat(product.price_unit) + (product.price_unit / 100 * select.type_payments[0].coefficient);

      if(product.articles.rubros_id == 20){
        descuento = precio_unit / 100 * 5
      }

      if(product.articles.rubros_id == 12){
        descuento = precio_unit / 100 * 12
      }

      cantidad = cantidad + product.quantity;
      precio = product.quantity * parseFloat(precio_unit) - descuento;
    });

    return {
      cantidad: cantidad,
      precio: precio,
      precio_sn: precio,
    };
  };


  if(select.type_payments.length == 0 && select.reports.length == 0){
    return (
      <div
      class="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "700px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>No existe lista de productos</h1>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-3">
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-secondary rounded-pill me-1"
                name="close"
                onClick={(event) => onClick(event)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }

  return (
    <div
      class="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        class="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "700px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div class="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>Productos</h1>
              <span class="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                class="border-0 bg-transparent"
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="body p-3">
            <table className="table">
                <thead>
                  <tr>
                    <th className="text-nowrap">Orden Cod.</th>
                    <th className="text-nowrap">Imagen</th>
                    <th className="text-nowrap">Nombre</th>
                    <th className="text-nowrap">Codigo Int.</th>
                    <th className="text-nowrap">Cantidad</th>
                    <th className="text-nowrap">Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    select.reports.map((product) => {

                      let precio = parseFloat(product.price_unit) + (product.price_unit / 100 * select.type_payments[0].coefficient)
                      let descuento = 0;

                      if(product.articles.rubros_id == 20){
                        descuento = precio / 100 * 5
                      }

                      if(product.articles.rubros_id == 12){
                        descuento = precio / 100 * 12
                      }

                      return <tr>
                      <td className="text-start">{product.code_orders}</td>
                      <td className="text-start">{""}</td>
                      <td className="text-start">{product.name}</td>
                      <td className="text-start">{product.code}</td>
                      <td className="text-end">{product.quantity}</td>
                      <td className="text-end">$ {( parseFloat(product.price_unit) + (product.price_unit / 100 * select.type_payments[0].coefficient) - descuento ).toFixed(2)}</td>
                    </tr>
                    })
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={5} className="text-end">{Totals(select.reports).cantidad}</td>
                    <td className="text-end text-nowrap">$ {(Totals(select.reports).precio_sn).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>Tipo de pago {select.type_payments[0].payment_method + " %" +  (- select.type_payments[0].coefficient)}</td>
                    <td className="text-end text-nowrap">$ {(Totals(select.reports).precio).toFixed(2)}</td>
                  </tr>
                </tfoot>
            </table>
          </div>
          <div class="footer bg-white p-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-secondary rounded-pill me-1"
                name="close"
                onClick={(event) => onClick(event)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalProducts;
