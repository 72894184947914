import { useState } from "react";
import CustomAlerts from "../../../../alerts/CustomAlerts";
import { apiRest, useRest } from "../../../../hooks/useRest";
import useUsers from "../../../../hooks/useUsers";
import Filtros from "./filtros/Filtros";
import ModalUser from "./modals/ModalUser";
import Tabla from "./tabla/Tabla";

const Usuarios = () => {
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [steps, setSteps] = useState(0);
  //states
  const [actions, setActions] = useState({
    showPassword: false,
  })
  //Modals
  const [modalUser, setModalUser] = useState(false);
  //Parametros
  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });
  const [params, setParams] = useState({
    url: "/Auth/Usuarios",
    search: "",
    status: "",
    pagination: 50,
  });

  const { users_data, users_message, users_isLoading } = useUsers(
    params.url,
    params,
    steps
  );

  //Formularios
  const [form, setForm] = useState({
    id: "",
    username: "",
    name: "",
    last_name: "",
    email: "",
    password: "",
    confirmation_password: "",
    address: "",
    phone: "",
    rol: "",
    status: "",
  });

  //Concat values
  const {
    id,
    username,
    name,
    last_name,
    email,
    password,
    address,
    phone,
    rol,
    confirmation_password,
    status
  } = form;

  //Eventos

  const onClick = async (e, value) => {
    e.preventDefault();

    switch (e.target.name) {
      //Actions
      case "change_status":{
          let data = value.data;
          let status = value.status;

          //recreate form
          let formData = new FormData();
          formData.append("id", data.id);
          formData.append("status", status);
          
          //send api rest
          const response = await apiRest({
            url: "/Auth/Usuarios/Actualizar",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          //setModalUser(false);
          setSteps(steps+1);
      } break;
      case "update_user":
        {
          //recreate form
          let formData = new FormData();

          formData.append("id", value.id);

          if(username.trim() !== "") formData.append("username", username);
          if(name.trim() !== "") formData.append("name", name);
          if(last_name.trim() !== "") formData.append("last_name", last_name);
          if(email.trim() !== "") formData.append("email", email);
          if(phone.trim() !== "") formData.append("phone", phone);
          if(address.trim() !== "") formData.append("address", address);
          if(password.trim() !== "") formData.append("password", password);
          if(confirmation_password.trim() !== "") formData.append("confirmation_password", confirmation_password);
          if(rol.trim() !== "") formData.append("rol", rol);


          //send api rest
          const response = await apiRest({
            url: "/Auth/Usuarios/Actualizar",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          //setModalUser(false);
          setSteps(steps+1);
        }
        break;
      case "add_user":
        {
          //Validation

          //Empty
          if (
            email.trim() == "" ||
            rol.trim() == "" ||
            password.trim() == "" ||
            confirmation_password.trim() == "" ||
            id.trim() == ""
          ) {
            setAlert(true);
            return CustomAlerts({
              msg: "Rellene los campos obligatorios",
              title: "Advertencia",
              type: "warning",
              size: "xl",
            });
          }

          //Password
          if (password !== confirmation_password) {
            setAlert(true);
            return CustomAlerts({
              msg: "Su contraseña no es igual",
              title: "Advertencia",
              type: "warning",
              size: "xl",
            });
          }

          console.log(id);

          //recreate form
          let formData = new FormData();
          //formData.append("username", username);
          //formData.append("name", name);
          //formData.append("last_name", last_name); optional
          formData.append("email", email);
          formData.append("id", id);
          //formData.append("phone", phone); optional
          //formData.append("address", address); optional
          formData.append("password", password);
          formData.append("confirmation_password", confirmation_password);
          formData.append("rol", rol);

          //empty create form
          Object.keys(form).map((item) => {
            if(
              form[item] !== "" &&
              item !== "email" &&
              item !== "password" &&
              item !== "confirmation_password" &&
              item !== "rol"
            ){
              formData.append(String(item), form[item]);
            }
          })


          
          //send api rest
          const response = await apiRest({
            url: "/Auth/Usuarios/Crear",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          //Actualizar
          setModalUser(false);
          setSteps(steps+1);
        }
        break;
      //Actions
      case "showPassword":{
        if(actions.showPassword){
          setActions({
            ...actions,
            [e.target.name]: false
          })
        } else {
          setActions({
            ...actions,
            [e.target.name]: true
          })
        }
      }
      break;  
      //Modals
      case "modal_user":
        {
          setModalUser(true);
          setSelect(value);
        }
        break;
      case "close":
        {
          setModalUser(false);
        }
        break;
    }
  };

  const onChange = (e) => {};

  const nextPage = (e) => {
    setParams({
      ...params,
      url: users_data.next_page_url,
    });
  };
  const prevPage = (e) => {
    setParams({
      ...params,
      url: users_data.prev_page_url,
    });
  };
  const links = (e, value) => {
    setParams({
      ...params,
      url: value,
    });
  };

  return (
    <div className="container-md">
      <div className="py-5">
        <h1>Usuarios</h1>
      </div>

      <div className="my-5">
        <Filtros
          initialStateFilter={initialStateFilter}
          setInitialStateFilter={setInitialStateFilter}
          params={params}
          setParams={setParams}
          steps={steps}
          setSteps={setSteps}
          onClick={onClick}
        />
      </div>

      <div className="my-5">
        {users_data ? (
          <Tabla
            onClick={onClick}
            onChange={onChange}
            nextPage={nextPage}
            prevPage={prevPage}
            links={links}
            data={users_data}
          />
        ) : (
          <div className="border-0 min-vh-100 d-flex justify-content-center align-items-center ">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>

      {modalUser ? (
        <ModalUser
          onClick={onClick}
          form={form}
          setForm={setForm}
          data={select}
          loading={loading}
          alert={alert}
          actions={actions}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Usuarios;
