import { useState } from "react";
import CustomAlerts from "../../../alerts/CustomAlerts";
import { Access } from "../../../helpers/AuthHelper";

const Login = () => {
  const [alert, setAlert] = useState({
    error: false,
    textError: ""
  });

  const [loadingBtn, setLoadingBtn] = useState(false)

  //ACTIONS

  const [actions, setActions] = useState({
    showPassword: false,
  })

  //FORM 

  const [form, setForm] = useState({
    email: "",
    password: ""
  })

  //BUTTON

  const onClick = (e) => {
    e.preventDefault();

    switch (e.target.name) {
      case "showPassword":{
        if(actions.showPassword){
          setActions({
            ...actions,
            [e.target.name]: false
          })
        } else {
          setActions({
            ...actions,
            [e.target.name]: true
          })
        }
      }
      break;
    }
  }

  const onChange = (e) => {

    switch (e.target.name) {
      case "password":
      case "email":{
        setForm({
          ...form,
          [e.target.name]: e.target.value
        })
      }
      break;
    }

  }

  const onAccess = async (e) => {
    //VALIDATION

    if(
      form.email.trim() == "" ||
      form.password.trim() == ""
    ){
      setAlert({
        error: true,
        textError: "El campo email o password se encuentra vacío."
      })
      return CustomAlerts({
        size: "xl",
        type: "warning",
        title: "Advertencia",
        msg: "El campo email o password se encuentra vacío."
      });
    }

    let dataForm = new FormData();

    dataForm.append('email', form.email);
    dataForm.append('password', form.password);

    const response = await Access(dataForm, setLoadingBtn);
  } 

  return (
    <section className="d-flex justify-content-center items-align-center min-vh-100">

    <div className="d-flex align-items-center">
        <form action="" className="p-5  rounded-10 border-0 form-control shadow-sm">

          <div className="my-4">
            <div className="d-flex justify-conntent-center w-100 mb-3">
              <img height="200px" className="img-fuild m-auto d-none" src="/LOGOTIPO.png" alt="" />
            </div>
            <h1 className="text-uppercase m-0 fs-3">Popeye</h1>
            <span className="fs-8 text-muted">Accede al administrador.</span>
          </div>
          
          <label for="basic-url" className="form-label">Email</label>
          <div className="input-group mb-3">
            <span className="input-group-text rounded-pill bg-primary border-primary text-white rounded-end"><i className="bi bi-person"></i></span>
            <input type="email" className="form-control border-primary rounded-pill rounded-start" placeholder="Email"  name="email" onChange={(event) => onChange(event)} required/>
          </div>

          {
            alert.error
            ? form.email.trim() == ""
            ? <div className="text-danger alert alert-danger p-2 fs-7 d-block">El campo se encuentra vacío.</div>
            : ""
            : ""
          }
          
          <label for="basic-url" className="form-label">Password</label>
          <div className="input-group mb-3">
            <label htmlFor="btnShowPassword" className={
                actions.showPassword
                ? "input-group-text rounded-pill bg-primary border-primary text-white rounded-end bi bi-eye"
                : "input-group-text rounded-pill bg-primary border-primary text-white rounded-end bi bi-eye-slash"
              }></label>
            <button className="d-none" id="btnShowPassword" name="showPassword" onClick={(event) => onClick(event)}>
            </button>
            <input 
            type={
              actions.showPassword
              ? "text"
              : "password"
            } 
            className="form-control border-primary rounded-pill rounded-start" placeholder="Password" aria-label="password" aria-describedby="basic-addon1" name="password" onChange={(event) => onChange(event)} required/>
          </div>

          {
            alert.error
            ? form.password.trim() == ""
            ? <div className="text-danger alert alert-danger p-2 fs-7 d-block">El campo se encuentra vacío.</div>
            : ""
            : ""
          }

          <button 
          type="button" 
          className="btn btn-primary text-white w-100" 
          onClick={(event) => onAccess(event)}
          disabled={loadingBtn}
          >
            {
              loadingBtn
              ? <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
              : "Login"
            }
          </button>
          <div className="text-center link-primary w-100">
          <a href="" className="fs-7 link-primary">¿Quieres recuperar tu contraseña? Click aquì.</a>
          </div>

          {
            alert.error
            ? <div className="text-danger alert alert-danger p-2 fs-7 d-block">{alert.textError}</div>
            : ""
          }
          
        </form>


    </div>

  </section>
  )
}

export default Login