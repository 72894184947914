const Body = ({children}) => {



  return (
    <main>
      {children}
    </main>
  )
}

export default Body