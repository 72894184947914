const Tabla = ({ data, onChange, onClick, nextPage, prevPage, links }) => {

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th colSpan={12} className="border-0">
              <div className="d-flex justify-content-end w-100 my-2">
                <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                  <ul class="pagination bg-transparent">
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8"
                          onClick={prevPage}
                          disabled={data.current_page == 1 ? true : false}
                        >
                          Anterior
                        </button>
                      ) : (
                        ""
                      )}
                    </li>

                    {data
                      ? data.links.map((item, index) => {
                          if (
                            (item.label !== "&laquo; Previous" &&
                              item.label !== "Next &raquo;" &&
                              item.active == true) ||
                            item.label == 1 ||
                            item.label == data.current_page - 1 ||
                            item.label == data.current_page + 1 ||
                            item.label == data.current_page + 2 ||
                            item.label == data.per_page ||
                            item.label == data.last_page
                          ) {
                            return (
                              <li
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true ? "fs-8" : "fs-8"
                                }
                              >
                                <button
                                  style={{ borderRadius: "5px" }}
                                  className={
                                    item.active == true
                                      ? "btn btn-warning me-1 fs-8"
                                      : "btn btn-outline-warning me-1 fs-8"
                                  }
                                  onClick={(event) => links(event, item.url)}
                                >
                                  {item.label}
                                </button>
                              </li>
                            );
                          }
                        })
                      : ""}
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8 "
                          onClick={nextPage}
                          disabled={
                            data.current_page == data.last_page ? true : false
                          }
                        >
                          Siguiente
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </th>
          </tr>
          <tr>
            <th className="border-0">Usuario</th>
            <th className="border-0">Nombre y apellido</th>
            <th className="border-0">Email</th>
            <th className="border-0">Telefono</th>
            <th className="border-0">Dirección</th>
            <th className="border-0">Rol</th>
            <th className="border-0">Estado</th>
            <th className="border-0"></th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((user) => (
            <tr>
              <td className="border-0">{user.username ? "" : user.username}</td>
              <td className="border-0">{user.name && user.last_name ? user.name + " " + user.last_name : ""}</td>
              <td className="border-0">{user.email}</td>
              <td className="border-0">{user.phone}</td>
              <td className="border-0">{user.address}</td>
              <td className="border-0">{user.roles.length > 0? user.roles[0].name : "Sin rol asignado"}</td>
              <td className="border-0">{user.status.description}</td>
              <td className="border-0">
                <label  className="btn btn-outline-success">
                <i  class="bi bi-pencil"></i>
                <button  className="d-none" name="modal_user" onClick={(event) => onClick(event, user)}></button>
                </label>
              </td>
              <td className="border-0">
                <div class="dropdown">
                  <button
                    class="btn btn-outline-dark rounded-pill dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cambiar estado...
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <label className="btn w-100 d-flex justify-content-start m-0">
                        <i class="text-success me-1 bi bi-check-circle-fill"></i>
                        Activar
                        <button className="d-none"
                        name="change_status"
                        onClick={(event) => onClick(event, {data:user, status:1})}></button>
                      </label>
                    </li>
                    <li>
                    <label className="btn w-100 d-flex justify-content-start m-0">
                      <i class="text-danger me-1 bi bi-x-circle-fill"></i>
                        Desactivar
                        <button className="d-none" 
                        name="change_status"
                        onClick={(event) => onClick(event, {data:user, status:2})}></button>
                      </label>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={12} className="border-0">
              <div className="d-flex justify-content-end w-100 my-2">
                <nav aria-label="Page navigation example bg-transparent d-flex justify-content-center align-items-center">
                  <ul class="pagination bg-transparent">
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8"
                          onClick={prevPage}
                          disabled={data.current_page == 1 ? true : false}
                        >
                          Anterior
                        </button>
                      ) : (
                        ""
                      )}
                    </li>

                    {data
                      ? data.links.map((item, index) => {
                          if (
                            (item.label !== "&laquo; Previous" &&
                              item.label !== "Next &raquo;" &&
                              item.active == true) ||
                            item.label == 1 ||
                            item.label == data.current_page - 1 ||
                            item.label == data.current_page + 1 ||
                            item.label == data.current_page + 2 ||
                            item.label == data.per_page ||
                            item.label == data.last_page
                          ) {
                            return (
                              <li
                                style={{ borderRadius: "5px" }}
                                className={
                                  item.active == true ? "fs-8" : "fs-8"
                                }
                              >
                                <button
                                  style={{ borderRadius: "5px" }}
                                  className={
                                    item.active == true
                                      ? "btn btn-warning me-1 fs-8"
                                      : "btn btn-outline-warning me-1 fs-8"
                                  }
                                  onClick={(event) => links(event, item.url)}
                                >
                                  {item.label}
                                </button>
                              </li>
                            );
                          }
                        })
                      : ""}
                    <li class="page-item">
                      {data ? (
                        <button
                          className="btn btn-outline-warning me-1 fs-8 "
                          onClick={nextPage}
                          disabled={
                            data.current_page == data.last_page ? true : false
                          }
                        >
                          Siguiente
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Tabla;
