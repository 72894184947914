import { useEffect, useState } from "react";
import CustomAlerts from "../../../../alerts/CustomAlerts";
import { apiRest } from "../../../../hooks/useRest";
import useRubro from "../../../../hooks/useRubro";
import Filtros from "./filtros/Filtros";
import ModalArticle from "./modals/ModalArticle";
import Tabla from "./tablas/Tabla";
import clientAxios from "../../../../config/ConfigAxios";
import { ReadCsv } from "../../../../utils/readCsv";
import { format } from "date-fns";

const allowedExtensions = ["csv"];

const Articulos = () => {
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(null);
  const [steps, setSteps] = useState(0);
  const [alert, setAlert] = useState(false);
  const [art_data, setArtData] = useState(null);
  const [art_isLoading, setArtIsLoading] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const [files, setFiles] = useState(null);

  //Modals
  const [modalArt, setModalArt] = useState(false);
  //Parametros
  const [initialStateFilter, setInitialStateFilter] = useState({
    nombre_cliente: "",
    search: "",
  });

  const [params, setParams] = useState({
    url: "/Auth/Productos",
    search: "",
    status: "",
    rubros: [],
    subrubros: [],
    pagination: 50,
  });

  const [params_r, setParamsR] = useState({
    url: "/Auth/Rubros",
  });
  //Formularios
  const [form, setForm] = useState({
    image_url: null,
    image: "",
    name: "",
    description: "",
    price: "",
    stock: "",
    status: "",
    code: "",
    rubros_id: "",
    subrubros_id: "",
    //property
    value_size: "",
    value_colour: "",
    size: null,
    colour: null,
  });

  const {
    image,
    name,
    description,
    price,
    stock,
    status,
    code,
    rubros_id,
    subrubros_id,
    size,
    colour,
  } = form;

  //Hooks

  /*
  const { art_data, art_message, art_isLoading } = useArt(
    params.url,
    params,
    steps
  );
  */

  const { rubro_data, rubro_message, rubro_isLoading } = useRubro(
    params_r.url,
    params_r,
    0
  );

  //Eventos
  const onClick = async (e, value) => {
    switch (e.target.name) {
      //
      case "update_article":
        {
          //recreate form
          let formData = new FormData();
          formData.append("id", value.id);

          //empty create form
          Object.keys(form).map((item) => {
            if (form[item] !== "" && item !== "size" && item !== "colour") {
              formData.append(String(item), form[item]);
            }

            if(item == "status"){
              formData.append(String(item), form[item] == 'Activo' ? 1 : 2);
            }
          });

          //send api rest
          const response = await apiRest({
            url: "/Auth/Productos/Actualizar",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          //
          setSteps(steps + 1);
        }
        break;
      case "add_article":
        {
          //Empty
          if (
            name.trim() == "" ||
            rubros_id == "" ||
            subrubros_id == "" ||
            status == "" ||
            price == "" ||
            stock == ""
          ) {
            setAlert(true);
            return CustomAlerts({
              msg: "Rellene los campos obligatorios",
              title: "Advertencia",
              type: "warning",
              size: "xl",
            });
          }

          //recreate form
          let formData = new FormData();
          formData.append("name", form.name);
          //formData.append("description", form.description);
          formData.append("stock", form.stock);
          formData.append("price", form.price);
          formData.append("code", form.code);
          formData.append("status", form.status);
          formData.append("rubros_id", rubros_id);
          formData.append("subrubros_id", subrubros_id);

          //empty create form
          Object.keys(form).map((item) => {
            if (
              form[item] !== "" &&
              item !== "name" &&
              item !== "stock" &&
              item !== "price" &&
              item !== "rubros_id" &&
              item !== "subrubros_id"
            ) {
              formData.append(String(item), form[item]);
            }
          });

          /*
          if(image !== ""){
            formData.append("image", form.image);
          }

          if(colour){
            formData.append("colour", JSON.stringify(colour));
          }

          if(size){
          formData.append("size", JSON.stringify(size));
          }


          
          */

          //send api rest
          const response = await apiRest({
            url: "/Auth/Productos/Crear",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          //
          setSteps(steps + 1);
          setModalArt(false);
          //Reset states
          setForm({
            image_url: null,
            image: "",
            name: "",
            description: "",
            price: "",
            stock: "",
            status: "",
            code: "",
            rubros_id: "",
            subrubros_id: "",
            //property
            value_size: "",
            value_colour: "",
            size: null,
            colour: null,
          });
        }
        break;
      case "change_status":
        {
          let data = value.data;
          let status = value.status;

          //recreate form
          let formData = new FormData();
          formData.append("id", data.id);
          formData.append("status", status);

          //send api rest
          const response = await apiRest({
            url: "/Auth/Productos/Actualizar",
            type: "POST",
            form: formData,
            setLoading: setLoading,
          });

          //
          setSteps(steps + 1);
        }
        break;
      //
      case "colour":
      case "size":
        {
          let result = form[e.target.name] ? form[e.target.name] : value;
          let set_value = e.target.value;
          let state = false;
          let key = null;

          result.map((item, index) => {
            if (item == set_value) {
              state = true;
              key = index;
            }
          });

          if (state) {
            result.splice(key, 1);
          } else {
            result.push(set_value);
          }

          setForm({
            ...form,
            [e.target.name]: result,
          });
        }
        break;
      //Modals
      case "modal_art":
        {
          setModalArt(true);
          setSelect(value);
        }
        break;
      case "close":
        {
          setModalArt(false);

          //Reset states
          setForm({
            image_url: null,
            image: "",
            name: "",
            description: "",
            price: "",
            stock: "",
            status: "",
            code: "",
            rubros_id: "",
            subrubros_id: "",
            //property
            value_size: "",
            value_colour: "",
            size: null,
            colour: null,
          });
        }
        break;
    }
  };

  const nextPage = (e) => {
    setParams({
      ...params,
      url: art_data.next_page_url,
    });
  };
  const prevPage = (e) => {
    setParams({
      ...params,
      url: art_data.prev_page_url,
    });
  };
  const links = (e, value) => {
    setParams({
      ...params,
      url: value,
    });
  };

  //logs
  //console.log(rubro_data);
  const fetch = async (params) => {
    if (!art_isLoading) {
      setArtData(null);
      setArtIsLoading(true);

      const response = await clientAxios
        .get(params.url, { params, signal: controller.signal })
        .then((result) => {
          setController(new AbortController());
          setArtIsLoading(false);
          return setArtData(result.data.data);
        })
        .catch((result) => {
          setArtIsLoading(false);
          setController(new AbortController());
          if (result.code == "ERR_CANCELED") {
            setTimeout(() => {
              setSteps(steps + 1);
            }, 100);
          }

          return console.log(result.code);
        });
    } else {
      if (controller) {
        console.log("cancel");
        controller.abort();
      }
    }
  };

  useEffect(() => {
    let re_params = {};

    if (params.url.trim() != "") re_params = { ...re_params, url: params.url };
    if (params.search.trim() != "")
      re_params = { ...re_params, search: params.search };
    if (params.pagination != "")
      re_params = { ...re_params, pagination: params.pagination };
    if (params.rubros.length > 0)
      re_params = { ...re_params, rubros: params.rubros };
    if (params.subrubros.length > 0)
      re_params = { ...re_params, subrubros: params.subrubros };

    fetch(re_params);
  }, [params, steps]);

  useEffect(() => {
    const fetch = async () => {
      let params = {
        url: "/Auth/Files",
      };

      const response = await clientAxios
        .get(params.url, { params })
        .then((result) => {
          return setFiles(result.data);
        })
        .catch((result) => {
          setLoading(false);
          return console.log(result.code);
        });  
    };
    
    if (!files) {    
      fetch();   
    }
  }, []);

  //console.log(art_data);
  return (
    <div className="container-md">
      <div className="py-5">
        <h1>Productos</h1>
        <hr />
      </div>

      <div className="my-5">
        <h2 className="d-flex">
          Carga de archivos
          <ReadCsv />
        </h2>
        <hr />
        <div className="mb-3">
          <h5>Historial de archivos</h5>
          <hr />
          <div class="">
            {files
              ? files.map((f) => (
                  <label className="btn btn-outline-info mb-2 me-2">
                      <i class="bi bi-file-earmark-arrow-up"></i>
                      <div style={{ fontSize: "10px" }}>
                        {f.name +"-"+
                          format(new Date(f.created_at), "dd/MM/Y").toString()}
                      </div>
                      <button className="d-none"></button>
                  </label>
                ))
              : ""}
          </div>
        </div>
      </div>

      <div className="my-5">
        {rubro_data ? (
          <Filtros
            initialStateFilter={initialStateFilter}
            setInitialStateFilter={setInitialStateFilter}
            data={rubro_data}
            params={params}
            setParams={setParams}
            steps={steps}
            setSteps={setSteps}
            setArtData={setArtData}
            onClick={onClick}
            fetch={fetch}
            controller={controller}
            //setController={setController}
          />
        ) : (
          ""
        )}
      </div>

      <div className="my-5">
        {art_data ? (
          <Tabla
            onClick={onClick}
            nextPage={nextPage}
            prevPage={prevPage}
            links={links}
            data={art_data}
          />
        ) : (
          <div className="border-0 min-vh-100 d-flex justify-content-center align-items-center ">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>

      {modalArt ? (
        <ModalArticle
          onClick={onClick}
          form={form}
          setForm={setForm}
          loading={loading}
          alert={alert}
          data={{
            producto: select,
            rubros: rubro_data,
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Articulos;
