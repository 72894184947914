const ModalArticle = ({ form, setForm, data, onClick, alert }) => {
  const producto = data.producto;
  const rubros_ = data.rubros;

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "image":
        {
           setForm({
            ...form,
            [e.target.name]: e.target.files[0],
            image_url: URL.createObjectURL(e.target.files[0]),
          });
        }
        break;
      case "status":
      case "name":
      case "description":
      case "price":
      case "stock":
      case "subrubros_id":
      case "rubros_id":
      case "code":  
      case "value_colour":
      case "value_size":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
    }
  };

  const initialState = {
    created_at: "",
    code: "",
    colour: JSON.stringify([]),
    size: JSON.stringify([]),
    image: "",
    name: "",
    price: "",
    stock: "",
    rubros: null,
    subrubros: null,
    status: null,
    description: "",
  };

  const {
    created_at,
    code,
    image,
    name,
    description,
    price,
    stock,
    rubros,
    subrubros,
    status,
  } = producto ? producto : initialState;

  const size = producto 
  ? producto.size 
  ? producto.size !== "null" ? producto.size : initialState.size
  : initialState.size
  : initialState.size

  
  const colour = producto 
  ? producto.colour 
  ? producto.colour !== "null" ? producto.colour : initialState.colour
  : initialState.colour
  : initialState.colour

  //Logs
  //console.log(producto);
  console.log(colour);
  return (
    <div
      className="modal d-block effect-blur bg-dark bg-opacity-25 align-middle"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ zIndex: 104 }}
    >
      <div
        className="bg-light modal-dialog animation-modal my-md-5  overflow-hidden texto-normal"
        style={{ maxWidth: "900px", borderRadius: "20px" }}
      >
        <div className="modal-content bg-light">
          <div className="title m-0 bg-white p-4 d-flex justify-content-between">
            <div>
              <h1>
                {producto ? "Actualizar producto" : "Agregar nuevo producto"}
              </h1>
              <span className="fs-6 text-muted"></span>
            </div>
            <div>
              <button
                className="border-0 bg-transparent "
                name="close"
                onFocus={(event) => onClick(event, null)}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="body p-3">
            <div className="row bg-white p-3" autoComplete="new-off">
              <div className="col-md-12 mb-3">
                <div
                  className="bg-secondary bg-opacity-50 d-flex justify-content-center overflow-hidden"
                  style={{ height: "200px", borderRadius: "20px" }}
                >
                  <img
                    className="img-fluid"
                    src={
                      form.image_url
                        ? form.image_url
                        : producto
                        ? "https://devtesting.gq/assets/image/articles/"+producto.image
                        : ""
                    }
                    alt=""
                  />
                </div>
                <label for="exampleFormControlInput1" className="form-label">
                  Sube una imagen de tu producto.
                </label>
                <input
                  type="file"
                  className="form-control rounded-pill border-primary"
                  placeholder=""
                  name="image"
                  onChange={(event) => onChange(event)}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill border-primary"
                  placeholder="Ingresa un nombre a tu producto"
                  defaultValue={name}
                  name="name"
                  onChange={(event) => onChange(event)}
                />
                {alert ? (
                  form.name.trim() == "" ? (
                    <span className="text-danger">
                      Falta completar el nombre de usuario
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Codigo
                </label>
                <input
                  type="text"
                  className="form-control rounded-pill border-primary"
                  placeholder="Ingresa un nombre a tu producto"
                  defaultValue={code}
                  name="code"
                  onChange={(event) => onChange(event)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Describe tu producto
                </label>
                <textarea
                  className="form-control border-primary"
                  cols="30"
                  rows="10"
                  defaultValue={description}
                  name="description"
                  onChange={(event) => onChange(event)}
                ></textarea>
              </div>
              <div className="col-md-6 mb-3 ">
                <label for="exampleFormControlInput1" className="form-label">
                  Precio
                </label>
                <input
                  type="float"
                  className="form-control rounded-pill border-primary"
                  placeholder="Ingresa el precio de tu producto"
                  defaultValue={price}
                  name="price"
                  onChange={(event) => onChange(event)}
                />
                {alert ? (
                  form.price == "" ? (
                    <span className="text-danger">
                      Falta completar el precio de su producto
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6 mb-3 ">
                <label for="exampleFormControlInput1" className="form-label">
                  Stock
                </label>
                <input
                  type="number"
                  className="form-control rounded-pill border-primary"
                  placeholder="Ingresa el stock de tu producto"
                  defaultValue={stock}
                  name="stock"
                  onChange={(event) => onChange(event)}
                />
                {alert ? (
                    form.stock == "" ? (
                      <span className="text-danger">
                        Falta completar el stock de su producto
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
              </div>

              <div className="mb-3 ">
                <label for="exampleFormControlInput1" className="form-label">
                  Rubro
                </label>
                <select
                  className="form-select rounded-pill border-primary"
                  aria-label="Default select example"
                  name="rubros_id"
                  onChange={(event) => onChange(event)}
                >
                  <option selected>
                    {rubros ? rubros[0].name : "Seleccione un rubro..."}
                  </option>
                  {rubros_.map((rubro) => (
                    <option value={rubro.id}>{rubro.name}</option>
                  ))}
                </select>
                {alert ? (
                  form.rubros_id.trim() == "" ? (
                    <span className="text-danger">
                      Falta completar el rubro
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>

              {form.rubros_id !== "" || rubros ? (
                <div className="col-md-12 mb-3">
                  <label for="exampleFormControlInput1" className="form-label">
                    Sub rubro
                  </label>
                  <select
                    className="form-select rounded-pill border-primary"
                    aria-label="Default select example"
                    name="subrubros_id"
                    onChange={(event) => onChange(event)}
                  >
                    <option selected>
                      {subrubros
                        ? subrubros[0].name
                        : "Selecciona un sub rubro..."}
                    </option>
                    {rubros_.map((rubro) => {
                      if (rubro.id == form.rubros_id) {
                        return (
                          <>
                            {rubro.subrubros.map((subrubro) => (
                              <option value={subrubro.id}>
                                {subrubro.name}
                              </option>
                            ))}
                          </>
                        );
                      }
                    })}
                  </select>
                  {alert ? (
                    form.subrubros_id.trim() == "" ? (
                      <span className="text-danger">
                        Falta completar el sub rubro
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12 mb-3 ">
                <label for="exampleFormControlInput1" className="form-label">
                  Estado
                </label>
                <select
                  className="form-select rounded-pill border-primary"
                  aria-label="Default select example"
                  name="status"
                  onChange={(event) => onChange(event)}
                >
                  
                  { status.id = 1 ? <option selected>Activo</option> : <option value={1}>Activo</option> }
                  { status.id = 2 ? <option selected>Inactivo</option> : <option value={2}>Inactivo</option> }
                </select>
                {alert ? (
                    form.status == "" ? (
                      <span className="text-danger">
                        Falta completar el estado de su producto
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
              </div>

              <div className="col-md-12 mb-3 d-none">
                <div
                  class="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="w-100 py-3 btn btn-outline-dark rounded-pill collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {producto
                          ? "Propiedades especiales de tu producto"
                          : "Agregar propiedades especiales"}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <div className="mb-3">
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control border-primary"
                              placeholder="Agrega colores a tu producto"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              name="value_colour"
                              onChange={(event) => onChange(event)}
                            />
                            <label className="input-group-text btn btn-primary">
                              <i class="bi bi-plus"></i>
                              <button
                                className="d-none"
                                name="colour"
                                value={form.value_colour}
                                onClick={(event) => onClick(event, colour?  JSON.parse(colour) : [])}
                              ></button>
                            </label>
                          </div>
                          <ul class="list-group list-group-horizontal">
                            {
                            form.colour
                            ? form.colour.map((item) => (
                              <li class="list-group-item border-0 me-0 p-1">
                                <label className="btn btn-outline-primary rounded-pill px-3 ">
                                  <i class="bi bi-tag me-2"></i>
                                  {item}
                                  <button
                                    className="d-none"
                                    name="colour"
                                    value={item}
                                    onClick={(event) => onClick(event, colour?  JSON.parse(colour) : [])}
                                  ></button>
                                </label>
                              </li>
                              ))
                            : colour 
                            ? JSON.parse(colour).map((item) => 
                            (
                              <li class="list-group-item border-0 me-0 p-1">
                              <label className="btn btn-outline-primary rounded-pill px-3 ">
                                <i class="bi bi-tag me-2"></i>
                                {item}
                                <button
                                  className="d-none"
                                  name="colour"
                                  value={item}
                                  onClick={(event) => onClick(event, colour?  JSON.parse(colour) : [])}
                                ></button>
                              </label>
                            </li>
                            ))
                            : ""
                            }
                          </ul>
                        </div>
                        <div className="mb-3">
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control border-primary"
                              placeholder="Agrega tamaños disponibles a tu producto"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              name="value_size"
                              onChange={(event) => onChange(event)}
                            />
                            <label className="input-group-text btn btn-primary">
                              <i class="bi bi-plus"></i>
                              <button
                                className="d-none"
                                name="size"
                                value={form.value_size}
                                onClick={(event) => onClick(event, size?  JSON.parse(size) : [])}
                              ></button>
                            </label>
                          </div>
                          <ul class="list-group list-group-horizontal">
                            {
                              form.size
                              ? form.size.map((item) => (
                                <li class="list-group-item border-0 me-0 p-1">
                                  <label className="btn btn-outline-primary rounded-pill px-3 ">
                                    <i class="bi bi-tag me-2"></i>
                                    {item}
                                    <button
                                      className="d-none"
                                      name="size"
                                      value={item}
                                      onClick={(event) => onClick(event, size?  JSON.parse(size) : [])}
                                    ></button>
                                  </label>
                                </li>
                              ))
                              : size
                              ? JSON.parse(size).map((item) =>  
                              (
                                <li class="list-group-item border-0 me-0 p-1">
                                  <label className="btn btn-outline-primary rounded-pill px-3 ">
                                    <i class="bi bi-tag me-2"></i>
                                    {item}
                                    <button
                                      className="d-none"
                                      name="size"
                                      value={item}
                                      onClick={(event) => onClick(event, size?  JSON.parse(size) : [])}
                                    ></button>
                                  </label>
                                </li>
                              ))
                              : ""
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer bg-white p-4">
            <div className="d-flex justify-content-end">
              {producto ? (
                <button className="btn btn-primary rounded-pill me-2"
                name="update_article"
                onClick={(event) => onClick(event, producto)}>
                  Aplicar cambios
                </button>
              ) : (
                <button className="btn btn-primary rounded-pill me-2"
                name="add_article"
                onClick={(event) => onClick(event)}
                >
                  Guardar
                </button>
              )}
              <button
                className="btn btn-secondary rounded-pill me-1"
                name="close"
                onClick={(event) => onClick(event)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalArticle;
