import { createContext, useState } from "react";
import useAuth from "../hooks/useAuth";
export const authContext = createContext(); 

export const AuthProvider = ({children}) => {

const [steps, setSteps] = useState(0);
const [params, setParams] = useState({
  url: "/Auth/Me",
})

const {data, message, isLoading} = useAuth(params.url, params, steps)
return (
  <authContext.Provider value={{data}}>
    {!isLoading && children}
  </authContext.Provider>
)

}