const Filtros = ({
  initialStateFilter,
  setInitialStateFilter,
  params,
  setParams,
  steps,
  setSteps,
  onClick
}) => {
  const onChange = (e) => {
    switch (e.target.name) {
      case "status_id":{
        setParams({
          ...params,
          [e.target.name]: e.target.value
        })

        setSteps(steps+1);
      } break;
      case "search":
        {
          if (e.target.value.length < initialStateFilter.search.length) {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length == 0) {
              setParams({
                ...params,
                [e.target.name]: "",
              });

              setSteps(steps + 1);
            }
          } else {
            setInitialStateFilter({
              ...initialStateFilter,
              [e.target.name]: e.target.value,
            });
            if (e.target.value.length > 3) {
              setParams({
                ...params,
                [e.target.name]: e.target.value,
              });

              setSteps(steps + 1);
            }
          }
        }
        break;
      case "start_date":{
        setParams({
          ...params,
          [e.target.name]: e.target.value,
          "end_date": params.end_date.trim() == ""? e.target.value : params.end_date
        })

        return setSteps(steps+1);
      } break;
      case "end_date":{
        setParams({
          ...params,
          [e.target.name]: e.target.value,
          "start_date": params.start_date.trim() == ""? e.target.value : params.start_date
        })

        return setSteps(steps+1);
      } break;
    }
  };

  return (
    <div className="row">
      <div className="col-md-3">
        <div class="input-group mb-3">
          <input
            type="text"
            class=" border-primary rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
            placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            name="search"
            onChange={(event) => onChange(event)}
            autocomplete="nope"
          />
          <button
            class="input-group-text btn btn-primary px-2 p-0 border-primary text-white rounded-pill rounded-start"
            id="basic-addon2"
          >
            <i class="bi bi-search"></i>
          </button>
        </div>
      </div>
      <div className="col-md-3">
        <div class="input-group mb-3">
          <input
            type="date"
            class=" border-primary rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
            placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            value={params.start_date}
            name="start_date"
            onChange={(event) => onChange(event)}
            autocomplete="nope"
          />
          <button
            class="input-group-text btn btn-primary px-2 p-0 border-primary text-white rounded-pill rounded-start"
            id="basic-addon2"
          >
            <i class="bi bi-calendar-week"></i>
          </button>
        </div>
      </div>
      <div className="col-md-3">
        <div class="input-group mb-3">
          <input
            type="date"
            class=" border-primary rounded-pill form-control fs-9 p-0 px-2 py-1 rounded-end"
            placeholder="Busca tu reporte según el nombre del cliente o el codigo de entrada"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            value={params.end_date}
            name="end_date"
            onChange={(event) => onChange(event)}
            autocomplete="nope"
          />
          <button
            class="input-group-text btn btn-primary px-2 p-0 border-primary text-white rounded-pill rounded-start"
            id="basic-addon2"
          >
            <i class="bi bi-calendar-week"></i>
          </button>
        </div>
      </div>
      <div className="col-md-3">
        <select class="form-select rounded-pill border-primary" aria-label="Default select example" 
            name="status_id"
            onChange={(event) => onChange(event)}>
          <option selected>Selecciona un estado...</option>
          <option value={1}>Activos</option>
          <option value={2}>Inactivos</option>
          <option value={3}>Eliminados</option>
          <option value={4}>Pausados</option>
          <option value={5}>Recibimos tu pedido</option>
          <option value={6}>Cancelados</option>
          <option value={7}>Demorados</option>
          <option value={8}>Preparando pedido</option>
          <option value={9}>Comienzo de facturación</option>
          <option value={10}>Facturación final</option>
          <option value={11}>Envío en camino</option>
        </select>
      </div>
    </div>
  );
};

export default Filtros;
