import Swal from "sweetalert2"

const CustomAlerts = ({
  title,
  msg,
  type,
  size
}) => {


  switch (size) {
    case "xl":{

      switch (type) {
        case "warning":{
          return Swal.fire({
            icon: 'warning',
            title: title,
            text: msg,
            showConfirmButton: false,
          })
        }break;
        case "success":{
          return Swal.fire({
            icon: 'success',
            title: title,
            text: msg,
            showConfirmButton: false,
          })
        }break;
        case "error":{
          return Swal.fire({
            icon: 'error',
            title: title,
            text: msg,
            showConfirmButton: false,
          })
        }break;
      }

    }
    break;
    case "sm":{
      switch (type) {
        case "success":{
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
        
          return Toast.fire({
            icon: 'success',
            title: title,
            text: msg
          })
        } break;
      }
    }
    break;
  }

  return null;
}

export default CustomAlerts

